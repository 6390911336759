const $portraitBlock = $('.text-portrait-expand');
const $cards = $portraitBlock.find('.card');

$(".expand-icon").click(function(e) {
    const $this = $(this);
    
    if(($this.parents(".card").find(".minus-icon").length !== 0 ) || ($this.parents(".card").find(".plus-icon").length !== 0)) {
        $this.toggleClass("active")
        const $expandContent = $this.parents(".card").find(".expand-content");
        const $expandIconPlus = $this.parents(".card").find(".plus-icon");
        const $expandIconMinus = $this.parents(".card").find(".minus-icon");
        const $parent =  $this.parents(".card");
        e.preventDefault();

        if($parent.hasClass('expanded')) {
            setTimeout(function() {
                $parent.removeClass('expanded');
             }, 350);
        }
        else {
            $parent.addClass('expanded');
        }

        if($this.hasClass("active")) {
            $expandContent.eq(0).slideDown();
            $expandIconPlus.removeClass("active");
            $expandIconMinus.addClass("active");
        } else {
            $expandContent.eq(0).slideUp();
            $expandIconMinus.removeClass("active");
            $expandIconPlus.addClass("active");
        }
                
    }
});