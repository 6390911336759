const $links = $('.page-links .wrapper .page-link');

$links.hover(function() {
    let $this = $(this);
    cardHoverIn($this);
}, function() {
    let $this = $(this);
    cardHoverOut($this);
});

$links.focusin(function() {
    let $this = $(this);
    $this.addClass('focused');
    cardHoverIn($this);
});
$links.focusout(function() {
    let $this = $(this);
    $this.removeClass('focused');
    cardHoverOut($this);
});

function cardHoverIn(element) {
    if(element.find('.info .description').text().length > 0) {
        element.find('h2').addClass('hide');
    }
    element.find('.info').removeClass('anim');
    element.find('.info').addClass('show');
    element.find('.overlay').addClass('gradient');
}

function cardHoverOut(element) {
    element.find('h2').removeClass('hide');
    element.find('.info').addClass('anim');
    setTimeout(function() {
        element.find('.info').removeClass('show');
    }, 600);
    element.find('.overlay').removeClass('gradient');
}