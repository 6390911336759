import Glide from '@glidejs/glide'
let $prevSlideShown = 0;
const $imagesCount = $('.image-gallery .glide').data('count');
const $allSlides =  $('.image-gallery .glide .glide__slides .glide__slide');

function initImageSlider(id) {
    var imagesGlide = new Glide('#' + id, {
        type: 'carousel',
        keyboard: false,
        startAt: 0,
        perView: 1.4,
        gap: 32,
        breakpoints: {
            767: { gap: 16 },
            1023: { gap: 64 }
        },
        autoplay: false,
        animationDuration: 500,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });

    imagesGlide.on(['mount.after'], function() {
        let $activeSlide = $('.image-gallery .glide .glide__slide--active:not(.glide__slide--clone)');
        let $prevSlide = $activeSlide.prev();
        let $nextSlide = $activeSlide.next();
        $prevSlideShown = $activeSlide.data('index');
        $('.image-gallery .glide .glide__slide--clone').css({
            'transition' : 'none',
            'transform' : 'rotate(0) translateY(0) scale(1)'
        });
        $prevSlide.find('.glide-show-overlay').addClass('show');
        $prevSlide.css({
            'transition' : 'none',
            'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
        });
        $nextSlide.find('.glide-show-overlay').addClass('show');
        $nextSlide.css({
            'transition' : 'none',
            'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
        });
    });

    imagesGlide.on('run', function() {
        let $nextActiveIndex = imagesGlide.index;
        let $nextActiveSlide = $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveIndex);
        
        if($prevSlideShown == $imagesCount-1 && $nextActiveIndex == 0) {
            $('.image-gallery .glide .glide__slide--clone[data-index="0"]').find('.glide-show-overlay').removeClass('show');
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq(0).find('.glide-show-overlay').removeClass('show');
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq(0).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(0) translateY(0) scale(1)'
            });
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq(2).find('.glide-show-overlay').removeClass('show');
            $('.image-gallery .glide .glide__slide--clone').eq(2).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(0) translateY(0) scale(1)'
            });
            $('.image-gallery .glide .glide__slide--clone').eq(1).find('.glide-show-overlay').addClass('show');
            $('.image-gallery .glide .glide__slide--clone').eq(1).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
            });
            $('.image-gallery .glide .glide__slide--clone').eq(3).find('.glide-show-overlay').addClass('show');
            $('.image-gallery .glide .glide__slide--clone').eq(3).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
            });
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).find('.glide-show-overlay').addClass('show');
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
            }); 
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq(1).find('.glide-show-overlay').addClass('show');
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq(1).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
            }); 
        }
        else if($prevSlideShown == 0 && $nextActiveIndex == $imagesCount-1) {
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).find('.glide-show-overlay').removeClass('show');
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(0) translateY(0) scale(1)'
            });
            $('.image-gallery .glide .glide__slide--clone').eq(1).find('.glide-show-overlay').removeClass('show');
            $('.image-gallery .glide .glide__slide--clone').eq(1).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(0) translateY(0) scale(1)'
            });
            $('.image-gallery .glide .glide__slide--clone').eq(0).find('.glide-show-overlay').addClass('show');
            $('.image-gallery .glide .glide__slide--clone').eq(0).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
            });
            $('.image-gallery .glide .glide__slide--clone').eq(2).find('.glide-show-overlay').addClass('show');
            $('.image-gallery .glide .glide__slide--clone').eq(2).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
            });
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq(0).find('.glide-show-overlay').addClass('show');
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq(0).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
            });
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 2).find('.glide-show-overlay').addClass('show');
            $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 2).css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
            });
        }
        else if($prevSlideShown < $nextActiveIndex) {
            let $prevSlide = $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveIndex - 1);
            let $nextSlide = $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveIndex + 1);
            $prevSlide.find('.glide-show-overlay').addClass('show');
            $prevSlide.css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
            });
            $nextSlide.find('.glide-show-overlay').addClass('show');
            $nextSlide.css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
            });
            $nextActiveSlide.find('.glide-show-overlay').removeClass('show');
            $nextActiveSlide.css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(0) translateY(0) scale(1)'
            });
            if($prevSlideShown == ($imagesCount-2)) {
                $('.image-gallery .glide .glide__slide--clone[data-index="0"]').css({
                    'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                    'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
                });
                $('.image-gallery .glide .glide__slide--clone[data-index="0"]').find('.glide-show-overlay').addClass('show');
            }
        }
        else if ($prevSlideShown > $nextActiveIndex) {
            let $prevSlide = $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveIndex - 1);
            let $nextSlide = $('.image-gallery .glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveIndex + 1);
            $prevSlide.find('.glide-show-overlay').addClass('show');
            $prevSlide.css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
            });
            $nextSlide.find('.glide-show-overlay').addClass('show');
            $nextSlide.css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
            });
            $nextActiveSlide.find('.glide-show-overlay').removeClass('show');
            $nextActiveSlide.css({
                'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                'transform' : 'rotate(0) translateY(0) scale(1)'
            });
            if($prevSlideShown == ($imagesCount-2)) {
                $('.image-gallery .glide .glide__slide--clone[data-index="3"]').css({
                    'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
                    'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
                });
                $('.image-gallery .glide .glide__slide--clone[data-index="3"]').find('.glide-show-overlay').addClass('show');
            }
        }
    });

    imagesGlide.on('run.after', function() {
        let $activeSlide = $('.image-gallery .glide .glide__slide--active:not(.glide__slide--clone)');
        $prevSlideShown = $activeSlide.data('index');
    });

    var images = document.getElementById(id);
    if (images && images.querySelector('.glide__slides').children.length > 1) {
        imagesGlide.mount();

        var glideNavigation = images.querySelector('.glide-navigation');

        if (glideNavigation) {
            imagesGlide.on('run', function (e) {
                var items = glideNavigation.querySelectorAll('button');
                items.forEach(function (item) {
                    item.classList.remove('active');
                });
                items[imagesGlide.index].classList.add('active');
            });

            glideNavigation.querySelectorAll('button').forEach(function (link) {
                link.addEventListener('click', function (e) {
                    var elem = this;
                    e.preventDefault();
                    $allSlides.each(function(index){
                        $allSlides.eq(index).css({
                            'transition' : 'none',
                            'transform' : 'rotate(0) translateY(0)'
                        });
                    });
                    imagesGlide.go('=' + elem.getAttribute('data-id'));
                });
            });
        }
    }
}

window.initImageSlider = initImageSlider;