const $flexibleContent = $('.columns-flexible-content');
const $dropdownsBlock = $flexibleContent.find('.image-dropdowns');
const $dropdown = $flexibleContent.find('.dropdown');
const $close = $dropdown.find('.minus-sign');

$dropdown.click(function (e) {
    var $this = $(this);
    if ($this.hasClass('open')) {
        closeDropdown($this);
        $this.find('.expandable-content').slideUp(500);
    }
    else {
        $this.addClass('open');
        $this.find('.expandable-content').slideDown(500);
    }
});
    
$close.click(function() {
    if ($(this).parents('.dropdown').hasClass('open')) {
        $(this).parents('.dropdown').find('.expandable-content').slideUp(500);
        closeDropdown($(this).parents('.dropdown'));
/*     $('html, body').animate({
        scrollTop: $('.dropdown').first().offset().top - $('header').outerHeight()
    }, 400); */
    }
});

function closeDropdown(elem) {
    setTimeout(function () {
        elem.removeClass('open');
    }, 500);
}

if($dropdownsBlock.length > 0 && $(window).width() >= 768) {
    $dropdownsBlock.each(function(index){
        let $oddItems =  $dropdownsBlock.eq(index).find('.dropdown.item-odd');
        let $evenItems = $dropdownsBlock.eq(index).find('.dropdown.item-even');
        $oddItems.wrapAll('<div class="odd-items"></div>');
        $evenItems.wrapAll('<div class="even-items"></div>');
    });
}