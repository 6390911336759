const terminalNotifier = {
    messagebox: 'dramakort-banner',
    terminalActive: false,
    activeCardCount: 0,
    init: function () {
        const listenForEvents = ['terminalstatechange.sbasmapp', 'authstatechange.sbasmapp', 'currentsessionstate.sbasmapp'];

        const handleData = (data) => {
            const info = data.detail;

            if (info.active > 0) {
                this.activeCardCount = info.active;
            } else if (info.activeTerminalCards > 0) {
                this.activeCardCount = info.activeTerminalCards;
            } else if (info.active_terminal_card_count > 0) {
                this.activeCardCount = info.active_terminal_card_count;
            } else if (info.state == 0) {
                this.activeCardCount = 0;
            }

            if (this.activeCardCount > 0) {
                this.terminalActive = true;
                this.show();
            } else {
                this.terminalActive = false;
                this.hide();
            }
        }

        for (let e in listenForEvents) {
            document.addEventListener(listenForEvents[e], (event) => {
                handleData(event);
            });
        }
    },
    hide: function () {
        document.getElementById(this.messagebox).classList.remove('show');
        document.body.classList.remove('has-dramakort');
    },
    show: function () {
        document.getElementById(this.messagebox).classList.add('show');
        document.body.classList.add('has-dramakort');
    }
}

window.addEventListener('init.basm', (data) => {
    terminalNotifier.init();
});
