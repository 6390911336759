const $header = $('header');
const $headerHeight = $header.outerHeight();
const $menuWrapper = $('header .menu-wrapper');
const $menuWrapperHeight = $menuWrapper.height();
let $padding;
let lastScrollTop = 0;

const svgText = document.getElementById('now');
let dateString = (new Date).toLocaleDateString('no-NO', { day:'numeric' });
if(svgText) {
    svgText.textContent = dateString.replace('.', '');
}

if($(window).width() < 1024) {
    $padding = $headerHeight - $menuWrapperHeight - 14;
}
else {
    $padding = 0;
}

$(window).scroll(function (e) {
    var scrollTop = $(this).scrollTop();
    if (scrollTop <= $padding) {
        $header.removeClass('sticky show animate-header');
    } else if (scrollTop > $headerHeight) {
        if (scrollTop < lastScrollTop) { // Scrolling up
            $header.addClass('sticky animate-header show')
        } else {
            $header.addClass('sticky').removeClass('show');
        }
    }
    lastScrollTop = scrollTop;
});