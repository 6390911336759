const $blocks = $('.page-title');

if($blocks.length > 0 && $(window).width() < 768) {
    setTimeout(function() {
        $blocks.each(function() {
            let parentWidth = $(this).find('.wrapper').width();
            let titleWidth = $(this).find('.wrapper h1').width();
            if(titleWidth > parentWidth) {
                let scale = 1 - ((titleWidth - parentWidth) / titleWidth);
                $(this).find('.wrapper h1').css({
                    'transform' : 'scale(' + scale + ')'
                });
            }
        });
    }, 50);
}