import { intersectionObserve } from "./_intersection-observer";
import Glide from '@glidejs/glide';

const $slideshow = $('.hero .shows-wrapper .glide');
let $highlightedShow = $('.hero .highlighted-show');
const $firstHighlighted = $highlightedShow.html();
const showsWrapper = document.querySelectorAll('.hero .shows-wrapper');
const $arrows = $slideshow.find('.glide-buttons');
let $prevShowSlideShown = 0;
let $imagesCount = $('.hero .shows-wrapper .glide').data('count');
let slideWidth;
const $heroBlock = $('.hero');
let $currentBlock = $heroBlock;

$('.hero .continue-indicator a').click(function (e) {
    if ($(this).attr('href') === '#') {
        e.preventDefault();

        var $this = $(this);
        var $parent = $this.parents('.highlighted-show');
        var $next = $parent.next('*');
        let offset;

        if ($(window).width() < 1024) {
            offset = 100;
        }
        else {
            offset = 0;
        }
        if ($next.length > 0) {
            $('html, body').animate({
                scrollTop: $next.offset().top - offset
            }, 400);
        }
    }
});

setTimeout(function() {
    if($(window).width() >= 1024) {
        setHover();
    }
}, 500);

export const setHover = function (id) {
    $('.hero .shows-wrapper .glide').find('.glide__slide').hover(function(){
        $(this).css({
            'transform': 'scale(1.1)'
        });
        if(!$(this).hasClass('highlighted')) {
            $('.hero .glide__slide').removeClass('highlighted');
            $(this).addClass('highlighted');
            $(this).find('.text-content').addClass('show');
            $(this).find('.show-overlay').addClass('show');
            setHighlightedImage($(this));
        }
    }, function() {
        $(this).removeClass('highlighted');
        $(this).find('.text-content').removeClass('show');
        $(this).find('.show-overlay').removeClass('show');
        $(this).css({
            'transform': 'scale(1)',
        });
        setFirstHighlighted();
    });
    $('.hero .shows-wrapper .glide .glide__slide .show-card a').focusin(function(){
        $(this).parents('.glide__slide').css({
            'transform': 'scale(1.1)'
        });
        if(!$(this).parents('.glide__slide').hasClass('highlighted')) {
            $('.hero .glide__slide').removeClass('highlighted');
            $(this).parents('.glide__slide').addClass('highlighted');
            $(this).parents('.glide__slide').find('.text-content').addClass('show');
            $(this).parents('.glide__slide').find('.show-overlay').addClass('show');
            setHighlightedImage($(this).parents('.glide__slide'));
        }
    });
    $('.hero .shows-wrapper .glide .glide__slide .show-card a').focusout(function(){
        $(this).parents('.glide__slide').removeClass('highlighted');
        $(this).parents('.glide__slide').find('.text-content').removeClass('show');
        $(this).parents('.glide__slide').find('.show-overlay').removeClass('show');
        $(this).parents('.glide__slide').css({
            'transform': 'scale(1)',
        });
        setFirstHighlighted();
    });
}

let isPlaying;
function setHighlightedImage(elem) {
    let $video = elem.find('article').attr('data-video');
    if($video != "") {
        $highlightedShow.find('.image img').addClass('hide');
        $highlightedShow.find('.image video')[0].pause();
        $highlightedShow.find('.image video')[0].removeAttribute('src');
        $highlightedShow.find('.image video')[0].load();
        let $title = elem.find('.show-card .text-content h2').text().replace(/&LAQUO;|&RAQUO;/g, '"');
        //$(`<video playsinline autoplay muted><source src="" type="video/mp4"/></video>`).insertBefore(".image img");
        setTimeout(function() {
            let currentVideo = $highlightedShow.find('.image video')[0];
            $highlightedShow.find('.image video').attr('aria-label', $title);
            $highlightedShow.find('.image video source').attr('src', $video);
            
            if(currentVideo) {
                currentVideo.load();
                isPlaying = currentVideo.currentTime > 0 && !currentVideo.paused && !currentVideo.ended && currentVideo.readyState > currentVideo.HAVE_CURRENT_DATA;
                if (!isPlaying) {
                    currentVideo.play();
                }
            }
            $highlightedShow.find('.image video').removeClass('hide');
        }, 50);
    }
    else {
        $highlightedShow.find('.image img').addClass('hide');
        $highlightedShow.find('.image img').replaceWith(elem.find('.show-card img').clone());
        $highlightedShow.find('.image .gradient-overlay').addClass('hide');
        $highlightedShow.find('.image .gradient-overlay-desktop').addClass('hide');
        $highlightedShow.find('.image .gradient-overlay').replaceWith(elem.find('.gradient-overlay').clone());
        $highlightedShow.find('.image .gradient-overlay-desktop').replaceWith(elem.find('.gradient-overlay-desktop').clone());
        $highlightedShow.find('.image video').addClass('hide');
        $highlightedShow.find('.image video')[0].pause();
        $highlightedShow.find('.image video')[0].removeAttribute('src');
        $highlightedShow.find('.image video')[0].load();
        //$highlightedShow.find('.image video').remove();
        setTimeout(function() {
            $highlightedShow.find('.image img').removeClass('hide');
            $highlightedShow.find('.image .gradient-overlay').removeClass('hide');
            $highlightedShow.find('.image .gradient-overlay-desktop').removeClass('hide');
        }, 400);
    }
    $highlightedShow.find('.image .text-content h1').html(elem.find('.show-card .text-content h2').text().replace(/&LAQUO;|&RAQUO;/g, '"'));
    $highlightedShow.find('.image .text-content .subtitle').html(elem.find('.show-card .text-content .show-excerpt').html());
    $highlightedShow.find('.image .text-content .btn-wrapper a.solid-btn').hide();
    $highlightedShow.find('.image .text-content .btn-wrapper a.border-btn').hide();
    if($(window).width() < 1920) {
        $highlightedShow.find('.image .text-content').css({
            'bottom': '36vh'
        });
    }
    else {
        $highlightedShow.find('.image .text-content').css({
            'bottom': '40vh'
        });
    }
}

function setFirstHighlighted() {
    $highlightedShow.html($firstHighlighted);
}

intersectionObserve(showsWrapper, 0, (element, intersectionRatio) => {
    if (intersectionRatio == 1) {
        if($('.hero .glide .glide__track li').length > 3) {
            $arrows.addClass('show');
        }
    }
    else {
        $arrows.removeClass('show');
    }
});


function setSlideStyle(elem) {
    elem.css({
        'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
        'transform' : 'rotate(0) translateY(0) scale(1)'
    });
}

function setSlideLeft(elem) {
    elem.css({
        'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
        'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
    });
}

function setSlideRight(elem) {
    elem.css({
        'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
        'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
    });
}

export const initHeroSlider = function (id) {
    let slideGap;
        if($(window).width() >= 1024) {
            slideGap = 0;
        }
        else {
            slideGap = 48;
        }
    let $total = $('.hero .glide').data('total');
    if($total != undefined) {
        $imagesCount = $total;
    }
    if(showsGlide) {
        showsGlide.destroy();
    }

    var showsGlide = new Glide('#' + id, {
        type: 'carousel',
        keyboard: false,
        startAt: 0,
        perView: 3.8,
        gap: slideGap,
        breakpoints: {
            767: { perView: 1.4, gap: slideGap*0.33 },
            1023: { perView: 1.4, gap: slideGap*1.33 },
            1279: { perView: 2.7, gap: slideGap*0.67 },
            1439: { perView: 3.2, gap: slideGap*0.67 },
            1919: { perView: 3.2, gap: slideGap*0.67 },
            3000: { perView: 4.3, gap: slideGap*0.83 },
        },
        autoplay: false,
        animationDuration: 500,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });

    showsGlide.on(['mount.after'], function() {
        let $activeShow = $currentBlock.find('.glide .glide__slide--active:not(.glide__slide--clone)');
        let $prevShowSlide = $activeShow.prev();
        let $nextShowSlide = $activeShow.next();
        $activeShow.prev().css({
            'pointer-events': 'none'
        });

        $prevShowSlideShown = $activeShow.data('index');
        if($(window).width() < 1024) {
            $currentBlock.find('.glide .glide__slide--clone').css({
                'transition' : 'none',
                'transform' : 'rotate(0) translateY(0) scale(1)',
            });
            $prevShowSlide.find('.glide-show-overlay').addClass('show');
            $prevShowSlide.css({
                'transition' : 'none',
                'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
            });
            $nextShowSlide.find('.glide-show-overlay').addClass('show');
            $nextShowSlide.css({
                'transition' : 'none',
                'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
            });
        }
        $currentBlock.find('.glide .glide__slide--clone').find('.show-card a').attr('tabindex', '-1');
        $currentBlock.find('.glide .glide__slide--clone').find('button').attr('tabindex', '-1');
        $currentBlock.find('.glide .glide__slide--clone').find('.border-btn').attr('tabindex', '-1');
    });

    showsGlide.on('run', function() {
        let $nextActiveShowIndex = showsGlide.index;
        let $nextActiveShow = $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveShowIndex);
        if($(window).width() < 1024) {
            if($prevShowSlideShown == $imagesCount-1 && $nextActiveShowIndex == 0) {
                $currentBlock.find('.glide .glide__slide--clone[data-index="0"]').find('.glide-show-overlay').removeClass('show');
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).find('.glide-show-overlay').removeClass('show');
                setSlideStyle($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0));
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount-1).next().find('.glide-show-overlay').removeClass('show');
                setSlideStyle($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount-1).next());
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).prev().find('.glide-show-overlay').addClass('show');
                setSlideLeft($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).prev());
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount-1).next().next().find('.glide-show-overlay').addClass('show');
                setSlideRight($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount-1).next().next());
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).find('.glide-show-overlay').addClass('show');
                setSlideLeft($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1));
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(1).find('.glide-show-overlay').addClass('show');
                setSlideRight($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(1));
            }
            else if($prevShowSlideShown == 0 && $nextActiveShowIndex == $imagesCount-1) {
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).find('.glide-show-overlay').removeClass('show');
                setSlideStyle($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1));
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).prev().find('.glide-show-overlay').removeClass('show');
                setSlideStyle($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).prev());
                $currentBlock.find('.glide .glide__slide--clone').eq(0).find('.glide-show-overlay').addClass('show');
                setSlideLeft($currentBlock.find('.glide .glide__slide--clone').eq(0));
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).next().find('.glide-show-overlay').addClass('show');
                setSlideRight($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).next());
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).find('.glide-show-overlay').addClass('show');
                setSlideRight($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0));
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 2).find('.glide-show-overlay').addClass('show');
                setSlideLeft($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 2));
            }
            else if($prevShowSlideShown < $nextActiveShowIndex) {
                let $prevShowSlide = $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveShowIndex - 1);
                let $nextShowSlide = $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveShowIndex + 1);
                $prevShowSlide.find('.glide-show-overlay').addClass('show');
                setSlideLeft($prevShowSlide);
                $nextShowSlide.find('.glide-show-overlay').addClass('show');
                setSlideRight($nextShowSlide);
                $nextActiveShow.find('.glide-show-overlay').removeClass('show');
                setSlideStyle($nextActiveShow);
                if($prevShowSlideShown == ($imagesCount-2)) {
                    setSlideRight($nextActiveShow.next());
                    $nextActiveShow.next().find('.glide-show-overlay').addClass('show');
                }
            }
            else if ($prevShowSlideShown > $nextActiveShowIndex) {
                let $prevShowSlide = $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveShowIndex - 1);
                let $nextShowSlide = $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveShowIndex + 1);
                $prevShowSlide.find('.glide-show-overlay').addClass('show');
                setSlideLeft($prevShowSlide);
                $nextShowSlide.find('.glide-show-overlay').addClass('show');
                setSlideRight($nextShowSlide);
                $nextActiveShow.find('.glide-show-overlay').removeClass('show');
                setSlideStyle($nextActiveShow);
                if($nextActiveShowIndex == 0) {
                    setSlideLeft($nextActiveShow.prev());
                    $nextActiveShow.prev().find('.glide-show-overlay').addClass('show');
                }
            }
        }
    });

    showsGlide.on('run.after', function() {
        let $activeShow = $currentBlock.find('.glide .glide__slide--active:not(.glide__slide--clone)');
        $prevShowSlideShown = $activeShow.data('index');
        if(showsGlide.index > 0 && $('.hero .glide .glide-buttons.prev').length > 0) {
            $('.hero .glide .glide-buttons.prev').addClass('display-left');
        }
        else if ($('.hero .glide .glide-buttons.prev').length > 0) {
            $('.hero .glide .glide-buttons.prev').removeClass('display-left');
        }
        $currentBlock.find('.glide .glide__slide').css({
            'pointer-events': 'auto'
        });
        $activeShow.prev().css({
            'pointer-events': 'none'
        });
    });

    var showImages = document.getElementById(id);
    if ((showImages && window.innerWidth <1024 && showImages.querySelector('.glide__slides').children.length > 1) || (showImages && window.innerWidth >= 1024 && showImages.querySelector('.glide__slides').children.length > 3) ) {
        showsGlide.mount();

        var glideNavigation = showImages.querySelector('.glide-navigation');

        if (glideNavigation) {
            showsGlide.on('run', function (e) {
                var items = glideNavigation.querySelectorAll('a');
                items.forEach(function (item) {
                    item.classList.remove('active');
                });
                items[showsGlide.index].classList.add('active');
            });

            glideNavigation.querySelectorAll('a').forEach(function (link) {
                link.addEventListener('click', function (e) {
                    var elem = this;
                    e.preventDefault();
                    showsGlide.go('=' + elem.getAttribute('data-id'));
                });
            });
        }
    }
}
    
initHeroSlider($('.hero .shows-wrapper').data('id'));
 
$('.hero .show-filter #show-dates').click(function() {
    $('.ccal .calbody li').removeClass('first-selected last-selected only-selected');
    $('.ccal .calbody li.selected').first().addClass('first-selected');
    $('.ccal .calbody li.selected').last().addClass('last-selected');
    if($('.ccal .calbody li.selected').first().is($('.ccal .calbody li.selected').last())) {
        $('.ccal .calbody li.selected').first().removeClass('first-selected last-selected');
        $('.ccal .calbody li.selected').first().addClass('only-selected');
    }
    $('.ccal .calbody li.calnext').last().removeClass('first-selected last-selected');
    $('.ccal .calbody li.calprev').first().removeClass('first-selected last-selected');
});