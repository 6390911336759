let $shows;
if( $('.program').length > 0) {
    $shows = $('.program .shows-wrapper .tease-show');
}
else if ($('.magasin-section.current').length > 0) {
    $shows = $('.magasin-section .magasin-posts .tease-post');
}
else if ($('.magasin-section').length > 0) {
    $shows = $('.magasin-section .magasin-posts .tease-magasin_post');
}

if ($shows != undefined) {
    $shows.each(function() {
        $(this).find('.show-excerpt').css({
            'bottom': $(this).find('.btn-wrapper').height()
        })
    });

    if($(window).width() >= 1024) {
        $shows.hover(function() {
            let $this = $(this);
            $this.find('.show-card').addClass('scale-up');
            if($this.find('.text-content .show-excerpt').text().length > 0) {
                $this.find('.text-content h2').addClass('hide');
                $this.find('.text-content .metadata').addClass('hide');
                $this.find('.text-content .show-excerpt').removeClass('anim');
                $this.find('.text-content .show-excerpt').addClass('show');
            }
            $this.find('.gradient-overlay').addClass('excerpt');
        }, function() {
            let $this = $(this);
            $this.find('.show-card').removeClass('scale-up');
            $this.find('.text-content h2').removeClass('hide');
            $this.find('.text-content .metadata').removeClass('hide');
            $this.find('.text-content .show-excerpt').addClass('anim');
            setTimeout(function() {
                $this.find('.text-content .show-excerpt').removeClass('show');
            }, 500);
            $this.find('.gradient-overlay').removeClass('excerpt');
        });
    }

    $(window).resize(function(){
        $shows.each(function() {
            $(this).find('.show-excerpt').css({
                'bottom': $(this).find('.btn-wrapper').height()
            })
        });
    });
}