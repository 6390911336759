let $showsArchive = $('.blog .content-wrapper');
if ($showsArchive.length > 0) {
    let $years = $showsArchive.find('.years li a');
    let container = $showsArchive.find('.prev-shows')[0];
    $years.click(function(e) {
        e.preventDefault();
        $showsArchive.find('.years li').removeClass('active');
        let $year = $(this).data('slug');
        $(this).parent('li').addClass('active');
        getPosts($year);
    });
    function getPosts($selectedYear) {
        let data = new FormData();
        data.append('action', 'get_earlier_shows');
        data.append('year', $selectedYear);
        $.ajax({
            type: 'POST',
            url: rogalandteater_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                container.innerHTML = res;
            }
        });
    }
}