$('footer .btn-scroll-top a').click(function (e) {
  e.preventDefault();
  $('html, body').animate(
    {
      scrollTop: 0,
    },
    300
  );
});

let $anchorCount = 0;

$('a.visually-hidden').click(function (e) {
  if ($(this).attr('href') === '#') {
    e.preventDefault();
    let $parent = $(this).parents('section');
    let $next = $parent.next('*').first();
    let $url;
    if ($next.attr('id') == undefined) {
      $next.attr('id', 'anchor-' + $anchorCount);
      $anchorCount++;
    }
    $url = $next.attr('id');
    window.location.hash = '';
    window.location.hash = $url;
  }
});

$('button.visually-hidden').click(function (e) {
  if ($(this).attr('href') === '#') {
    e.preventDefault();
    let $parent = $(this).parents('section');
    let $next = $parent.next('*').first();
    let $url;
    if ($next.attr('id') == undefined) {
      $next.attr('id', 'anchor-' + $anchorCount);
      $anchorCount++;
    }
    $url = $next.attr('id');
    window.location.hash = '';
    window.location.hash = $url;
  }
});
