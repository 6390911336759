const $imageTextBlock = $('.image-with-text').first();
const $blocks = $('.image-with-text');

if($imageTextBlock && $('#content-section').children().first().is($imageTextBlock)) {
    $imageTextBlock.addClass('no-margins');
}

if($blocks.length > 0 && $(window).width() < 768) {
    setTimeout(function() {
        $blocks.each(function() {
            let parentWidth = $(this).find('.text-content').width();
            let titleWidth = $(this).find('.text-content h2').width();
            if(titleWidth > parentWidth) {
                let scale = 1 - ((titleWidth - parentWidth) / titleWidth);
                $(this).find('.text-content h2').css({
                    'transform' : 'scale(' + scale + ')'
                });
            }
        });
    }, 50);
}