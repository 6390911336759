import bodymovin from 'lottie-web/build/player/lottie_light.min.js'

var animations = document.querySelectorAll('.lottie-logo-animation');
let animationObject;

function setup() {
    animations.forEach((element, i) => loadAnimation(element, i));
}

if(animations.length > 0) {
    setup();
    setTimeout(setup, 1000);
    setTimeout(setup, 3000);
}

// set up animation object for each element
function loadAnimation(element, i) {
    if (element.classList.contains('loaded')) {
        return;
    }

    animationObject = bodymovin.loadAnimation({
        container: element, // Required
        path: '/wp-content/themes/grensesnitt/static/animation/rogaland_teater_logo_hover.json', // Required
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: false, // Optional
        name: "", // Name for future reference. Optional.
    });

    element.classList.add('loaded');
}

if(animations.length > 0) {
    // add event listeners for hover effect
    document.querySelector(".logo-desktop").addEventListener("mouseenter", function(event) {
        animationObject.setDirection(1);
        animationObject.play();
    })
    document.querySelector(".logo-desktop").addEventListener("mouseleave", function(event) {
        animationObject.setDirection(-1);
        animationObject.play();
    })
}
