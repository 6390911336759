var $menuIcon = $('header .menu-wrapper #nav-icon');
var $searchIcon = $('header:not(.show-menu) .menu-wrapper .search-link');
var $searchInput = $('header:not(.show-menu) .menu-wrapper #search input');
var $searchInputMobile = $('header:not(.show-menu) .menu-wrapper #search-mobile input');
var $header = $('header');
var $body = $('body');
var $navMenu = $('header .menu-wrapper .nav-menu');
var $navLink = $('.menu-links .menu-item-has-children a');
var $navLinkArrow = $('.menu-links .menu-item-has-children svg');
$('.menu-links .menu-item-has-children').removeClass('active');

function hideMenu() {
    $header.removeClass('show-menu');
    if(!$header.hasClass("show-search")) {
        $body.removeClass('no-scroll');
    } 
    $('ul').removeClass('submenu-open');
    $('li').removeClass('active');
}

function hideSearch() {
    $header.removeClass('show-search');
    if(!$header.hasClass("show-menu")) {
        $body.removeClass('no-scroll');
    } 
}

function hideSearchMobile() {
    $header.removeClass('show-search');
    if(!$header.hasClass("show-menu")) {
        $body.removeClass('no-scroll');
    } 
}

if ($menuIcon.length > 0) {
    $menuIcon.click(function () {
        if ($header.hasClass('show-menu')) {
            hideMenu();
        } else if($header.hasClass("show-search")) {
            $body.addClass('no-scroll');
            $header.addClass('show-menu');
            hideSearch();
        } else {
            $body.addClass('no-scroll');
            $header.addClass('show-menu');
        }
    });

    $($navLinkArrow).click(function (e) {
        e.preventDefault();
        if ($(this).closest('li').hasClass('active')) {
            if ($(this).closest('li').children('ul').hasClass('first-children')) {
            }
            else if ($(this).closest('li').children('ul').hasClass('second-children')) {
                $(this).closest('li').children('ul.second-children').removeClass('submenu-open');
            }
            $(this).closest('li').removeClass('active');
        }
        else {
            $(this).closest('li').addClass('active');
            if ($(this).closest('li').children('ul').hasClass('second-children')) {
                $(this).closest('li').children('ul.second-children').addClass('submenu-open');
            }
        }
    });

    $($navLink).click(function (e) {
        e.preventDefault();
        if ($(this).closest('li').hasClass('active')) {
            window.location = e.target.href;
        }
        else {
            $(this).closest('li').addClass('active');
            if ($(this).closest('li').children('ul').hasClass('first-children')) {
            }
            else if ($(this).closest('li').children('ul').hasClass('second-children')) {
                $(this).closest('li').children('ul.second-children').addClass('submenu-open');
            }
            else if ($(this).closest('ul').hasClass('first-children') || ($(this).closest('ul').hasClass('second-children'))) {
                window.location = e.target.href;
            }
        }
    });
}

if($searchIcon.length > 0) {
    $searchIcon.click(function(e) {
        e.preventDefault();

        if($header.hasClass("show-menu") && window.innerWidth < 1000) {
            
            if ($header.hasClass('show-search')) {
                hideSearchMobile();
            } else {
                $body.addClass('no-scroll');
                $header.addClass('show-search');
                if(window.innerWidth > 1000) {
                    $searchInput.focus();
                } else {
                    $searchInputMobile.focus();
                }
            }
        } else {
            if ($header.hasClass('show-search')) {
                hideSearch();
            } else if($header.hasClass("show-menu")) {
                $body.addClass('no-scroll');
                $header.addClass('show-search');
                if(window.innerWidth > 1000) {
                    $searchInput.focus();
                } else {
                    $searchInputMobile.focus();
                }
                hideMenu();
            } else {
                $body.addClass('no-scroll');
                $header.addClass('show-search');
                if(window.innerWidth > 1000) {
                    $searchInput.focus();
                } else {
                    $searchInputMobile.focus();
                }
            }
        }
    });

    $(window).resize(function() {
        if(window.innerWidth > 1000) {
            $header.removeClass("show-search");
        }
    });
}