// Origin live (default)
var SUBMIT_URL = 'https://but.origin.no/rt-but-medlemsregister/api/application/submit';
var TEST = false;

if (location.href.includes('but.staging.origin.no')) {
    // Origin staging
    SUBMIT_URL = 'http://but.staging.origin.no/rt-but-medlemsregister/api/application/submit';
    TEST = false;
}

/*
if (location.href.includes('rogaland-teater.test') || location.href.includes('staging-rogalandteaterno.grensesnitt.cloud')) {
    // Origin staging
    SUBMIT_URL = 'http://but.staging.origin.no/rt-but-medlemsregister/api/application/submit';
    TEST = true;
}
*/

if (location.href.includes('torbjorn.origin.no')) {
    // Togga dev
    SUBMIT_URL = 'http://torbjorn.origin.no:8080/rt-but-medlemsregister/api/application/submit';
    TEST = true;
}
if (location.href.includes('andre.origin.no')) {
    // andre dev
    SUBMIT_URL = 'http://andre.origin.no:8080/rt-but-medlemsregister/api/application/submit';
    TEST = true;
}

if (TEST) console.log('Target URL: ' + SUBMIT_URL);


$('#application-form').on('submit', function (e) {
    e.preventDefault();

    var $form = $(this);

    if (!validateForm($form)) return;

    var queryString = $form.serialize();

    $.ajax({
        url: SUBMIT_URL,
        method: 'POST',
        data: queryString,
        success: function (e) {
            if (!TEST) $('#application-form button').remove();

            $('.application-message')
                .removeClass('application-message-error')
                .addClass('application-message-success')
                .show()
                .text('Søknaden er registrert! Du vil om kort tid motta bekreftelse på epost.');
        },
        error: function (e) {
            if (e.responseJSON && e.responseJSON.problems) {
                showProblems(e.responseJSON.problems);
            } else showError();
        }
    });
});

function validateForm($form) {
    var problems = [];

    var isBlank = function (selector) {
        return $form.find(selector).val().trim().length === 0;
    };

    if (isBlank('#firstName')) {
        problems.push("Fornavn kan ikke være tomt");
    }

    if (isBlank('#lastName')) {
        problems.push("Etternavn kan ikke være tomt");
    }

    if (isBlank('#address')) {
        problems.push("Adresse kan ikke være tom");
    }

    if (isBlank('#postalCode')) {
        problems.push("Postnummer kan ikke være tomt");
    }
    else if ($form.find('#postalCode').val().trim().length !== 4) {
        problems.push("Postnummer må være fire siffer");
    }

    if (isBlank('#postalArea')) {
        problems.push("Poststed kan ikke være tomt");
    }

    if (isBlank('#birthDateYear')) {
        problems.push("Fødselsår kan ikke være tomt");
    }
    else if ($form.find('#birthDateYear').val().trim().length !== 4) {
        problems.push("Fødselsår må være fire siffer");
    }

    if (isBlank('#phone') && isBlank('#phoneParent')) {
        problems.push("Minst ett telefonnummer må være oppgitt");
    }

    if (isBlank('#email') && isBlank('#emailParent')) {
        problems.push("Minst én epostadresse må være oppgitt");
    }

    if ($('#jente, #gutt, #nonbinary').is(':checked') === false) {
        problems.push("Kjønn må være valgt");
    }

    if ($('#personalInformationConsent').is(':checked') === false) {
        problems.push("Samtykke for å behandle personopplysninger må være godkjent");
    }
    if ($('#recordingConsent').is(':checked') === false) {
        problems.push("Samtykke for eventuelle opptak må være godkjent");
    }

    if (problems.length > 0) {
        showProblems(problems);
        return false;
    }
    return true;
}

function showProblems(problems) {
    $('.application-message')
        .addClass('application-message-error')
        .show()
        .text('Vennligst rett opp følgende, og prøv på ny:')
        .append('<ul/>')
        .append($(problems).map(function (idx, problem) {
            return $('<li/>', { text: problem });
        }).get());
}

function showError() {
    $('.application-message')
        .addClass('application-message-error')
        .show()
        .text('Det har oppstått en feil! Vennligst prøv igjen senere.');
}