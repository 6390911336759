$('.image-hero .continue-indicator a').click(function (e) {
    if ($(this).attr('href') === '#') {
        e.preventDefault();

        var $this = $(this);
        var $parent = $this.parents('.image-hero');
        var $next = $parent.next('*');
        let offset;

        if ($(window).width() < 1024) {
            offset = 100;
        }
        else {
            offset = 50;
        }
        if ($next.length > 0) {
            $('html, body').animate({
                scrollTop: $next.offset().top - offset
            }, 400);
        }
    }
});